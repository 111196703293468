.event_video_player {
  user-select: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
  .video-react {
    padding: 0 !important;
    padding-top: 0 !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  video {
    height: 100%;
  }
  &:hover {
    .btn {
      display: flex;
    }
  }
}

.btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000066;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}