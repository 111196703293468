
.remove {
  width: 20px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    svg {
      path {
        fill: #dc3545;
      }
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
}